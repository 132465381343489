import React, { useState } from "react"

//Redux
import { useSelector } from "react-redux"

//Router
import { navigate } from "gatsby"

// Components
import Section from "../Tools/Section/Section"
import CommunicationBar from "../Tools/CommunicationBar/CommunicationBar"

/* Screenshots */
// Web
import webDashboardTr from "../../assets/company/muni-dashboard-web-tr.webp"
import webDashboardEn from "../../assets/company/muni-dashboard-web-en.webp"
import webAnalyticsTr from "../../assets/company/muni-analytics-web-tr.webp"
import webAnalyticsEn from "../../assets/company/muni-analytics-web-en.webp"
import webLimits from "../../assets/company/muni-limits-web.png"
import IntegrationIllustrationEN from "../../assets/company/integration-illustration-EN.webp"
import IntegrationIllustrationTR from "../../assets/company/integration-illustration-TR.webp"

// Expense
import expenseMessageTR from "../../assets/company/expense-message-TR.png"
import expenseMessageEN from "../../assets/company/expense-message-EN.png"

// Mobile
import appDashboard from "../../assets/company/muni-dashboard-mobile.png"
import appAnalytics from "../../assets/company/muni-analytics-mobile.png"
import appApprovals from "../../assets/company/muni-approvals-mobile.png"
import appScanResult from "../../assets/company/muni-ai-result-mobile.png"
import favIcon from "../../assets/muni_favicon.ico"

/* */

// Localized
import expenseManagementData from "../../localized/expenseManagementLocalized.json"
import communicationBarData from "../../localized/communicationBarLocalized.json"
import featuresOverviewData from "../../localized/featuresOverviewLocalized.json"
import buttonsData from "../../localized/buttonsLocalized.json"
import Helmet from "react-helmet"

const ExpenseManagement = ({
  creatingExpensesRef,
  reviewingExpensesRef,
  expenseLimitsRef,
  analyticsRef,
  integratedMessagingRef,
}) => {
  const currentLanguage = useSelector(state => state.changeLanguageReducer)

  const expenseMessagesImages = {
    en: {
      src: `${expenseMessageEN}`,
      alt: "message",
    },
    tr: {
      src: `${expenseMessageTR}`,
      alt: "message",
    },
  }

  const IntegrationIllustrations = {
    en: {
      src: `${IntegrationIllustrationEN}`,
      alt: "integration illustration",
    },
    tr: {
      src: `${IntegrationIllustrationTR}`,
      alt: "integration illustration",
    },
  }

  // Email value for communication bar
  const [contactEmail, setContactEmail] = useState("")

  const sectionOne = (
    <div id="expense-management-first-section">
      <div className="media_section flex_column">
        <div>
          <div
            className="section_paragraph"
            id="expense-management-first-section-paragraph"
          >
            <span
              className="section_paragraph_top wow animate__fadeIn"
              data-wow-duration="1.5s"
            >
              {expenseManagementData.firstSectionTitle?.[currentLanguage]}
            </span>
            <span
              className="section_paragraph_middle wow animate__fadeIn"
              data-wow-duration="2s"
            >
              {expenseManagementData.firstSectionSubTitle?.[currentLanguage]}
            </span>
            <div
              className="section_paragraph_bottom wow animate__fadeIn"
              data-wow-duration="2.5s"
            >
              <span>
                {
                  expenseManagementData.firstSectionDescription?.[
                    currentLanguage
                  ]
                }
              </span>
            </div>
          </div>
          <div className="flex jc_content ac_items flex_column">
            <span className="muni_xxlarge_text bold_text mb-3">
              {expenseManagementData.noCreditCardRequired?.[currentLanguage]}!
            </span>
            <button
              className="website_btn signup_btn text non_styled_link text-white margin_bottom_5"
              onClick={() => navigate("https://app.muni.com.tr/signup")}
              // className="website_btn whiteOutline_btn discover_btn mb-4"
            >
              {expenseManagementData.signUp?.[currentLanguage]}
            </button>
          </div>
          <div
            className="wow animate__fadeIn media_section_content d-none d-md-flex"
            data-wow-duration="3s"
          >
            <img
              src={currentLanguage === "tr" ? webDashboardTr : webDashboardEn}
              alt="Web Dashboard"
              className="media_section_content_web black_media_shadow"
            />
          </div>
          <div className="media_section_content d-flex d-md-none">
            <img
              src={appDashboard}
              alt="App Dashboard Mobile"
              className="media_section_content_mobile black_media_shadow wow animate__fadeIn"
              data-wow-duration="3s"
            />
          </div>
        </div>
      </div>
    </div>
  )

  const sectionTwo = (
    <div
      ref={creatingExpensesRef}
      id="expense-management-second-section"
      className="media_section"
    >
      <div
        data-wow-duration="1s"
        className="media_section_content"
        id="expense-management-second-section-left"
      >
        <img
          src={appScanResult}
          alt="App Scan Screenshot"
          className="media_section_content_mobile black_media_shadow"
        />
      </div>
      <div
        id="expense-management-second-section-right"
      >
        <div
          className="section_paragraph"
        >
          <span className="section_paragraph_top">
            {expenseManagementData.secondSectionTitle?.[currentLanguage]}
          </span>
          <span className="section_paragraph_middle">
            {expenseManagementData.secondSectionSubTitle?.[currentLanguage]}
          </span>
          <div className="section_paragraph_bottom">
            {expenseManagementData.secondSectionDescription?.[currentLanguage]
              .split(".")
              .map((item, index) => {
                if (item.length > 0) {
                  return (
                    <span
                      className={`margin_none ${index === 1 ? "my-4" : ""}`}
                    >
                      {item}.
                    </span>
                  )
                }
                return null
              })}
          </div>
        </div>
      </div>
    </div>
  )

  const sectionThree = (
    <div
      ref={reviewingExpensesRef}
      id="expense-management-third-section"
      className="media_section"
    >
      <div
        className="section_paragraph"
        id="expense-management-third-section-paragraph"
      >
        <span
          data-wow-duration="1s"
          className="wow animate__fadeIn section_paragraph_top"
          id="third-section-title"
        >
          {expenseManagementData.thirdSectionTitle?.[currentLanguage]}
        </span>
        <span
          className="wow animate__fadeIn section_paragraph_middle"
          data-wow-duration="1.5s"
          id="third-section-subtitle"
        >
          {expenseManagementData.thirdSectionSubTitle?.[currentLanguage]}{" "}
        </span>
        <div
          className="wow animate__fadeIn section_paragraph_bottom"
          data-wow-duration="2s"
        >
          <span>
            {expenseManagementData.thirdSectionDescription?.[currentLanguage]}
          </span>
        </div>
      </div>
      <div
        className="wow animate__fadeIn media_section_content"
        data-wow-duration="2s"
      >
        <img
          src={appApprovals}
          alt="Home mockup"
          className="media_section_content_mobile black_media_shadow"
        />
      </div>
    </div>
  )

  const sectionFour = (
    <div
      ref={expenseLimitsRef}
      id="expense-management-fourth-section"
      className="media_section"
    >
      <div
        className="wow animate__fadeIn media_section_content"
        data-wow-duration="2s"
      >
        <img
          src={webLimits}
          alt="Home mockup"
          className="media_section_content_web black_media_shadow"
          id="expense-management-fourth-section-custom-image"
        />
      </div>
      <div
        className="section_paragraph"
        id="expense-management-fourth-section-paragraph"
      >
        <span
          data-wow-duration="1s"
          className="wow animate__fadeIn section_paragraph_top"
          id="fourth-section-title"
        >
          {expenseManagementData.fourthSectionTitle?.[currentLanguage]}
        </span>
        <span
          className="wow animate__fadeIn section_paragraph_middle"
          data-wow-duration="1.5s"
          id="fourth-section-subtitle"
        >
          {expenseManagementData.fourthSectionSubTitle?.[currentLanguage]}{" "}
        </span>
        <div
          className="wow animate__fadeIn section_paragraph_bottom"
          data-wow-duration="2s"
        >
          <span>
            {expenseManagementData.fourthSectionDescription?.[currentLanguage]}
          </span>
        </div>
      </div>
    </div>
  )

  const sectionFive = (
    <div ref={integratedMessagingRef} id="expense-management-fifth-section">
      <div
        className="section_paragraph"
        id="expense-management-fifth-section-paragraph"
      >
        <span
          data-wow-duration="1s"
          className="wow animate__fadeIn section_paragraph_top"
        >
          {expenseManagementData.fifthSectionTitle?.[currentLanguage]}
        </span>
        <span
          className="wow animate__fadeIn section_paragraph_middle"
          data-wow-duration="1.5s"
        >
          {expenseManagementData.fifthSectionSubTitle?.[currentLanguage]}{" "}
        </span>
        <div
          className="wow animate__fadeIn section_paragraph_bottom"
          data-wow-duration="2s"
        >
          <span className="mb-0">
            {expenseManagementData.fifthSectionDescription?.[currentLanguage]}
          </span>
        </div>
      </div>
      <div
        className="wow animate__fadeIn media_section_content"
        id="expense-management-fifth-section-media"
        data-wow-duration="2s"
      >
        <img
          src={expenseMessagesImages?.[currentLanguage].src}
          alt={expenseMessagesImages?.[currentLanguage].alt}
          className={`media_section_content_web black_media_shadow`}
        />
      </div>
    </div>
  )

  const sectionSix = (
    <div
      ref={analyticsRef}
      id="expense-management-sixth-section"
      className="media_section flex_column"
    >
      <div
        className="section_paragraph"
        id="expense-management-sixth-section-paragraph"
      >
        <span
          data-wow-duration="1s"
          className="wow animate__fadeIn section_paragraph_top"
        >
          {expenseManagementData.sixthSectionTitle?.[currentLanguage]}
        </span>
        <span
          className="wow animate__fadeIn section_paragraph_middle"
          data-wow-duration="1.5s"
        >
          {expenseManagementData.sixthSectionSubTitle?.[currentLanguage]}{" "}
        </span>
        <div
          className="wow animate__fadeIn section_paragraph_bottom"
          data-wow-duration="2s"
        >
          <span>
            {expenseManagementData.sixthSectionDescription?.[currentLanguage]}
          </span>
        </div>
      </div>
      <div
        className="wow animate__fadeIn media_section_content"
        data-wow-duration="2s"
      >
        <img
          src={currentLanguage === "tr" ? webAnalyticsTr : webAnalyticsEn}
          alt="Analytics Page Web"
          className="media_section_content_web black_media_shadow d-none d-md-block"
        />
        <img
          src={appAnalytics}
          alt="Analyics Page Mobile"
          className="media_section_content_mobile black_media_shadow d-block d-md-none"
        />
      </div>
    </div>
  )

  const sectionSeven = (
    <div id="features-overview-fifth-section">
      <div
        id="features-overview-fifth-section-top"
        className="wow animate__fadeInUp"
        data-wow-duration="1s"
      >
        <img
          src={IntegrationIllustrations?.[currentLanguage].src}
          alt="Integration Illustration"
        />
      </div>
      <div
        id="features-overview-fifth-section-bottom"
        className="wow animate__fadeIn"
        data-wow-duration="2s"
      >
        <div className="section_paragraph ac_items margin_top_3">
          <span className="section_paragraph_top">
            {featuresOverviewData.sitRelax?.[currentLanguage]}
          </span>
          <span className="section_paragraph_middle margin_bottom_4">
            {featuresOverviewData.weWillTakeCare?.[currentLanguage]}
          </span>
          <div className="section_paragraph_bottom w-80">
            <span className="text_align_center margin_bottom_2">
              {featuresOverviewData.integrationsInMind?.[currentLanguage]}
            </span>
            <span className="light_bold_text text_align_center">
              {featuresOverviewData.talkToUs?.[currentLanguage]}
            </span>
          </div>
        </div>
        <div className="flex jc_content">
          <button
            onClick={() =>
              navigate(`/company/contact-us/?lang=${currentLanguage}`)
            }
            className="website_btn discover_btn margin_top_3"
          >
            {buttonsData.contactUs?.[currentLanguage]}
          </button>
        </div>
      </div>
    </div>
  )

  const sectionEight = (
    <div
      className="communication_bar_container wow animate__fadeIn"
      data-wow-duration="2s"
      id="expense-management-seventh-section"
    >
      <div className="communication_bar_header">
        {`${communicationBarData.readyToStart?.[currentLanguage]} ?`}
      </div>
      <CommunicationBar
        id="expense-management-communication-bar"
        inputValue={contactEmail}
        changeInputValue={setContactEmail}
        navigateLink={`https://app.muni.com.tr/signup/?snemail=${contactEmail}`}
        blankPage={true}
        type="signUp"
      />
    </div>
  )

  return (
    <div className="expense-management">
      <Helmet>
        <title>Muni</title>
        <meta name="icon" href={favIcon} />
        <meta
          name="description"
          content="Rydoo Alternative. Muni helps you stay on top of your company's expenses. Scan, manage, and track your expenses. Great expense management at no cost."
        />
        <meta
          name="keywords"
          content="expense management, concur, scan receipts, rydoo, per diem, expenses, expense tracker"
        />
        <meta property="og:title" content="Muni" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Rydoo Alternative. Muni helps you stay on top of your company's expenses. Scan, manage, and track your expenses. Great expense management at no cost."
        />
        {/* <meta property="og:image" content="" /> */}
        <meta property="og:locale" content="en_UK" />
        <meta property="og:url" content="https://usemuni.com" />
        <link rel="canonical" href="https://usemuni.com" />
        <link rel="icon" href={favIcon} />
      </Helmet>
      <Section
        backgroundColor="white"
        sectionContent={sectionOne}
        topSection={true}
      />
      <Section
        backgroundColor="white"
        sectionContent={sectionTwo}
        topSection={false}
      />
      <Section backgroundColor="white" sectionContent={sectionThree} />
      <Section
        backgroundColor="gray"
        sectionContent={sectionFour}
        otherClassName="media_section"
      />
      <Section
        backgroundColor="gray"
        sectionContent={sectionFive}
        otherClassName="media_section"
      />
      <Section
        backgroundColor="white"
        sectionContent={sectionSix}
        otherClassName="media_section"
        sectionId="expense-management-sixth-section-wrapper"
      />
      <Section
        backgroundColor="white"
        sectionContent={sectionSeven}
        sectionId="features-overview-fifth-section-wrapper"
        otherClassName="pt-0"
      />
      <Section
        backgroundColor="white"
        sectionContent={sectionEight}
        sectionId="expense-management-seventh-section-wrapper"
        otherClassName="pt-0"
      />
    </div>
  )
}

export default ExpenseManagement
